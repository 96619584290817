.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.people-list {
    width:260px;
    float: left;
  }
.people-list ul {
    list-style: none;
    padding: 20px;
}
.people-list ul li {
    padding-bottom: 20px;
}
.people-list img {
    float: left;
}
.people-list .about {
    float: left;
    margin-top: 8px;
    padding-left: 8px;
}

.chat-messages {
    margin-left: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.bubble-wrapper {
    padding: 10px 10px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-self: flex-end;
    color: #fff;
}
.inline-container {
    display: inline-flex;
}
.inline-container.own {
    flex-direction: row-reverse;
}
.inline-icon {
    width:60px;
    object-fit: contain;
    border-radius: 50%;
}
.message-icon {
    margin: 17px
}
.own-bubble {
    min-width: 60px;
    max-width: 700px;
    padding: 14px 18px;
    margin: 6px 8px;
    background-color: #5b5377;
    border-radius: 16px 16px 0 16px;
    border: 1px solid #443f56;
}
.other-bubble {
    min-width: 60px;
    max-width: 700px;
    padding: 14px 18px;
    margin: 6px 8px;
    background-color: #6C8EA4;
    border-radius: 16px 16px 16px 0;
    border: 1px solid #54788e;

}
.own {
    align-self: flex-end;
}
.other {
    align-self: flex-start;
}

.msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.msg-info-name {
    margin-right: 10px;
    font-weight: bold;
}
.msg-info-time {
    font-size: 0.85em;
}
.msg-image {
    width: 100%
}

.msger-inputarea {
    display: flex;
    padding: 10px;
    border-top: 2px solid #ddd;
    background: #eee;
}
.msger-inputarea * {
    padding: 10px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
}
.msger-input {
    flex: 1;
    background: #ddd;
}
.msger-send-btn {
    margin-left: 10px;
    background: rgb(0, 196, 65);
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.23s;
}
.msger-send-btn:hover {
    background: rgb(0, 180, 50);
}
.msger-emoji-btn {
    font-weight: bold;
    cursor: pointer;
}
